import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { UserCrmHistory } from "@/store/interfaces/CRM";
import { PaginationResponse, SortingPair } from "@/store/interfaces/Datatables";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class CRMModule extends VuexModule {
  userHistoryRecords: UserCrmHistory[] = [];

  pagination: PaginationResponse = { per_page: 10 } as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getCrmUserHistoryRecords(): UserCrmHistory[] {
    return this.userHistoryRecords;
  }
  get getCrmUserHistoryPagination(): PaginationResponse {
    return this.pagination;
  }
  get getCrmUserHistorySorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.CRM_SET_LOADED_USER_HISTORY](data) {
    this.userHistoryRecords = data.data;
  }

  @Mutation
  [Mutations.CRM_SET_USER_HISTORY_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.CRM_SET_USER_HISTORY_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.CRM_LOAD_USER_HISTORY](payload) {
    ApiService.setHeader();
    return new Promise<UserCrmHistory[]>((resolve, reject) => {
      ApiService.post("crm/get-user-history", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.CRM_SET_LOADED_USER_HISTORY,
              data["user_crm_history"]
            );
            this.context.commit(
              Mutations.CRM_SET_USER_HISTORY_PAGINATION,
              data["user_crm_history"].pagination
            );
            resolve(data["user_crm_history"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "crm.errors.cantGetUserCrmHistory"
          );
          reject();
        });
    });
  }
}
