export const userContactEventsEn = {
  userContactEvents: {
    description_text:
      "<div>Reason : <strong>{reason}</strong>, contacted via: <strong>{contacted_via}</strong>.</div><div>Result: <strong>{result}</strong></div>",
    modal: {
      title: "User contact event reports",
      last30: "Last 30 records",
    },
    toolTips: {
      contact: "User contact report for user {name}",
    },
    errors: {
      cantAddUserContactEvent: "Error saving user contact report",
      cantGetUserContactEvents: "Failed to fetch contact reports data",
    },
    reasons: {
      "not-in-training": "not in training",
      "after-diagnostic": "after diagnostic",
      "ending-program": "ending program",
      "training-renewal": "training renewal",
      "order-diagnostic": "order diagnostic",
      leads: "lead",
      "scheduled-to-contact": "scheduled to contact",
      common: "info",
    },
    via: {
      phone: "phone",
      email: "email",
      in_person: "in person",
      not_contacted: "not contacted",
      does_not_pick_up: "does not pick up",
      sms: "SMS",
    },
    title: "Report of contacting user {name}.",
    form: {
      userContactEventAdded: "User contact report saved",
      reason: "Reason",
      contacted_via: "Contacted via",
      result: "Result",
      nextContactEvent: "Contact again on",
      nextDay: "day",
      note: "Note",
    },
  },
};
