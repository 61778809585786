export const crmCs = {
  crm: {
    title: "CRM",

    history: {
      title: "Historie",
      table: {
        activity: "Aktivita",
        created_at: "Datum",
        created_by: "Vytvořil",
        data: "Data",
        link: "Odkaz",
      },
      activities: {
        contact_event: "Záznam o kontaktování",
        program_started: "Začátek programu",
        program_finished: "Konec programu",
        program_paused: "Program pozastaven",
        invoice_created: "Vytvořena faktura",
        invoice_sent: "Faktura odeslána",
        invoice_paid: "Faktura zaplacena",
      },
      noRecords: "Není k dispozici žádná historie",
      searchRecords: "Hledat v historii",
    },
    errors: {
      cantGetUserCrmHistory: "Nepodařilo se získat historii aktivit uživatele",
    },
  },
};
