export const crmEn = {
  crm: {
    title: "CRM",

    history: {
      title: "History",
      table: {
        activity: "Activity",
        created_at: "Date created",
        created_by: "Created by",
        data: "Data",
        link: "Link",
      },
      activities: {
        contact_event: "Contact event",
        program_started: "Program started",
        program_finished: "Program finished",
        program_paused: "Program paused",
        invoice_created: "Invoice created",
        invoice_sent: "Invoice sent",
        invoice_paid: "Invoice paid",
      },
      noRecords: "No records found",
      searchRecords: "Search records",
    },
    errors: {
      cantGetUserCrmHistory: "Failed to fetch user CRM history",
    },
  },
};
