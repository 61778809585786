export const companyEn = {
  companies: {
    unassigned: "No company",
    toolTips: {
      edit: "Edit company {company}",
      delete: "Delete company {company}",
    },
    editTitle: "Edit company",
    newTitle: "New company",
    addCompany: "Add company",
    errors: {
      notFound: "Company {id} not found",
      notYourRole: "Cant access data for company {id}",
      nameExists: "Company named `{name}` already exists",
      commonGET_COMPANY_BY_ID: "Error retrieving company data",
      commonDELETE_COMPANY_BY_ID: "Error deleting company",
      commonDELETE_COMPANIES: "Error deleting companies",
      cantGetCompanies: "Failed to fetch companies data",
      logoUploadFailed: "Failed to upload logo image",
    },
    form: {
      name: "Name",
      companyChanged: "Company was stored",
      companyChangedFail: "Failed to store company",
      companyAdded: "New company was created",
      companyAddFail: "Failed to add new company",
      logo: "Logo",
      trainingInfo: "Training info",
      ad_banner: "Ad banner",
      diagnostics: "Diagnostics",
      addLogo: "Add logo",
      changeLogo: "Change logo",
      removeLogo: "Remove logo",
      allowedFileTypes: "Allowed file types",
      type: "Type",
      invoiceDetails: "Invoice details",
      invoiceCompanies: "Possibility of invoicing for these companies as well",
      types: { company: "Company", organization: "Organization" },
      country: "Country",
      email: "Email",
      street: "Street",
      city: "City",
      zip: "Zip",
      in: "Company registration Id",
      tin: "Tax Id",
      note_after: "Text after invoice items",
    },
    table: {
      header: {
        name: "Name",
        diagnostics: "Diagnostics",
        type: "Type",
        actions: "Actions",
      },
      deleteCompanyQuestion:
        "Do you really want to delete company <strong>{company}</strong>?",
      searchCompanies: "Search companies",
      companyDeleted: "Company {company} was deleted",
      companiesDeleted: "Companies were deleted",
      noRecords: "No companies found",
    },
  },
};
