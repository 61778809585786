import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Notification } from "@/store/interfaces/User";

@Module
export default class NotificationModule extends VuexModule {
  redirectTo = "";
  loadedNotification: Notification = {} as Notification;
  Notifications: Notification[] = [];
  pagination: PaginationResponse = { per_page: 5 } as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedNotification(): Notification {
    return { ...this.loadedNotification } as Notification;
  }

  /**
   * Retreive current loaded notifications
   */
  get getNotifications(): Notification[] {
    return this.Notifications;
  }

  /**
   * Get last pagination setup
   */
  get getNotificationsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getNotificationsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_NOTIFICATION](Notification: Notification) {
    this.loadedNotification = Notification;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS](data) {
    this.Notifications = data.data;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_NOTIFICATIONS](payload) {
    ApiService.setHeader();
    return new Promise<Notification[]>((resolve, reject) => {
      ApiService.post("notifications", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_NOTIFICATIONS,
              data["notifications"]
            );
            this.context.commit(
              Mutations.SET_NOTIFICATIONS_PAGINATION,
              data["notifications"].pagination
            );
            resolve(data["notifications"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "notifications.errors.cantGetNotifications"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_NOTIFICATION_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Notification>((resolve) => {
        const Notification = {} as Notification;
        this.context.commit(Mutations.SET_LOADED_NOTIFICATION, Notification);
        resolve(Notification);
      });
    }
    return new Promise<Notification>((resolve, reject) => {
      ApiService.get(`notification/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_NOTIFICATION,
              data["notification"]
            );
            resolve(data["notification"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "notifications.errors.commonGET_NOTIFICATION_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_NOTIFICATION_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Notification>((resolve, reject) => {
      ApiService.delete(`notification/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_NOTIFICATION,
              data["notification"]
            );
            resolve(data["notification"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "notifications.errors.commonDELETE_NOTIFICATION_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_NOTIFICATION](Notification: Notification) {
    const payload = JSON.parse(JSON.stringify(Notification));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`notification/${Notification.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_NOTIFICATION,
              data["notification"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Notification.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "notifications.form.notificationChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_NOTIFICATION](Notification: Notification) {
    const payload = JSON.parse(JSON.stringify(Notification));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`notification`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_NOTIFICATION,
              data["notification"]
            );
            resolve(data["notification"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Notification.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "notifications.form.notificationAddFail"
          );
          reject();
        });
    });
  }
}
