export const notificationCs = {
  notifications: {
    welcome: "Vítejte v iSenses",
    welcomeBack: "Vítejte zpět v iSenses {name}",
    newEshopOrder: "Děkujeme za Vaši objednávku na senses.zone č. {orderNr}",
    failedToStartPurchasedProgram:
      "Nepodařilo se spustit Vámi zakoupený program na senses.zone obj. č. {orderNr}. Prosím kontaktujte tým iSenses na {phone} nebo {email}",
    purchasedProgramStarted:
      "Byl spuštěn Vámi zakoupený program {programName}, obj. č. {orderNr}. Můžete začít trénovat.",
    clientsMenuUpdated: "Menu klientů se změnilo",
    newSenaptecResultsReceived: "Bylo přijato nové měření z přístroje Senaptec",
    companyLogoChanged: "Logo firmy bylo aktualizovano",
    title: "Připomínky",
    toolTips: {
      edit: "Upravit připomínku {notification}",
      delete: "Odstranit připomínku {notification}",
    },
    editTitle: "Upravit připomínku",
    newTitle: "Nová připomínka",
    addNotification: "Přidat připomínku",
    errors: {
      notFound: "Připomínka id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit připomínku id {id}",
      commonGET_NOTIFICATION_BY_ID: "Chyba při získávání dat o připomínkách",
      commonDELETE_NOTIFICATION_BY_ID: "Chyba při odstraňování připomínky",
      commonDELETE_NOTIFICATIONS: "Nepodařilo se odstranit připomínku",
      cantGetNotifications: "Chyba při získávání dat o připomínkách",
    },
    form: {
      name: "Název",
      company: "Firma",
      notificationChanged: "Připomínka byla upravena",
      notificationChangedFail: "Nepodařilo se upravit připomínku",
      notificationAdded: "Nová připomínka byl vytvořena",
      notificationAddFail: "Nepodařilo se vytvořit novou připomínku",
    },
    table: {
      history: "Připomínky",
      addRecord: "Nový záznam",
      header: {
        date_start: "Datum",
        message: "Text",
        closed: "Vyřízeno",
      },
      deleteNotificationQuestion:
        "Opravdu chcete odstranit připomínku <strong>{notification}</strong>?",
      searchNotifications: "Hledat připomínku",
      notificationDeleted: "Připomínka {notification} byl odstraněna",
      notificationsDeleted: "Připomínky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné připomínky",
    },
  },
};
