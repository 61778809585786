import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Company } from "@/store/interfaces/Company";

@Module
export default class CompanyModule extends VuexModule {
  redirectTo = "";
  loadedCompany: Company = {} as Company;
  Companies: Company[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedCompany(): Company {
    return {
      ...this.loadedCompany,
      ...{
        ad_banner: this.loadedCompany.ad_banner
          ? this.loadedCompany.ad_banner
          : {},
        additional_data: this.loadedCompany.additional_data
          ? this.loadedCompany.additional_data
          : {
              invoice: {
                place: null,
                number: null,
                note_after: null,
              },
            },
        registration_tokens:
          this.loadedCompany.registration_tokens &&
          this.loadedCompany.registration_tokens.length > 0
            ? this.loadedCompany.registration_tokens
            : [],
        diagnostics:
          this.loadedCompany.diagnostics &&
          this.loadedCompany.diagnostics.length > 0
            ? this.loadedCompany.diagnostics
            : [],
        invoice_companies:
          this.loadedCompany.invoice_companies &&
          this.loadedCompany.invoice_companies.length > 0
            ? this.loadedCompany.invoice_companies
            : [],
      },
    } as Company;
  }

  /**
   * Retreive current loaded companies
   */
  get getCompanies(): Company[] {
    return this.Companies.filter((company) => company.type == "company");
  }

  get getOrganizations(): Company[] {
    return this.Companies.filter((company) => company.type == "organization");
  }

  get getCompaniesAndOrganizations(): Company[] {
    return this.Companies;
  }

  get getCompaniesAndOrganizationsWithConnected(): Company[] {
    const pushedCompanyIds: number[] = [];

    for (const i in this.Companies) {
      if (
        this.Companies[i].invoice_companies &&
        this.Companies[i].invoice_companies.length > 0
      ) {
        for (const j in this.Companies[i].invoice_companies) {
          if (
            this.Companies.filter((company) => {
              return company.id === this.Companies[i].invoice_companies[j].id;
            }).length == 0
          ) {
            this.Companies.push(this.Companies[i].invoice_companies[j]);
            pushedCompanyIds.push(this.Companies[i].invoice_companies[j].id);
          }
        }
      } else {
        if (!pushedCompanyIds.includes(this.Companies[i].id)) {
          pushedCompanyIds.push(this.Companies[i].id);
        }
      }
    }
    return this.Companies;
  }

  /**
   * Get last pagination setup
   */
  get getCompaniesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getCompaniesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_COMPANY](Company: Company) {
    this.loadedCompany = Company;
  }

  @Mutation
  [Mutations.SET_COMPANIES](data) {
    this.Companies = data.data;
  }

  @Mutation
  [Mutations.SET_COMPANIES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_COMPANIES_SORTING](data) {
    this.sorting = data;
  }

  @Mutation
  [Mutations.SET_LOADED_COMPANY_LOGO](logo: string | null) {
    this.loadedCompany.logo = logo;
  }

  @Action
  [Actions.LOAD_COMPANIES](payload) {
    ApiService.setHeader();
    return new Promise<Company[]>((resolve, reject) => {
      ApiService.post("companies", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_COMPANIES, data["companies"]);
            this.context.commit(
              Mutations.SET_COMPANIES_PAGINATION,
              data["companies"].pagination
            );
            resolve(data["companies"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companies.errors.cantGetCompanies"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_COMPANY_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Company>((resolve) => {
        const Company = {} as Company;
        this.context.commit(Mutations.SET_LOADED_COMPANY, Company);
        resolve(Company);
      });
    }
    return new Promise<Company>((resolve, reject) => {
      ApiService.get(`company/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_COMPANY, data["company"]);
            resolve(data["company"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companies.errors.commonGET_COMPANY_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COMPANY_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Company>((resolve, reject) => {
      ApiService.delete(`company/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_COMPANY, data["company"]);
            resolve(data["company"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companies.errors.commonDELETE_COMPANY_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COMPANY](Company: Company) {
    const payload = JSON.parse(JSON.stringify(Company));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`company/${Company.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_COMPANY, data["company"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Company.id,
              name: Company.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companies.form.companyChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COMPANY](Company: Company) {
    const payload = JSON.parse(JSON.stringify(Company));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`company`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_COMPANY, data["company"]);
            resolve(data["company"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Company.id,
              name: Company.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "companies.form.companyAddFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_COMPANY_LOGO](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`company/upload-logo/${data.company_id}`, data.formData)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_COMPANY_LOGO, data.file);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = { id: data.company_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.logoUploadFailed"
          );
          reject();
        });
    });
  }
}
