import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Invoice, VatRate } from "@/store/interfaces/Invoice";
import { Company } from "@/store/interfaces/Company";
import moment from "moment";

@Module
export default class InvoiceModule extends VuexModule {
  redirectTo = "";
  loadedInvoice: Invoice = {} as Invoice;
  Invoices: Invoice[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedInvoice(): Invoice {
    return {
      ...this.loadedInvoice,
      ...{
        issue_date:
          this.loadedInvoice.issue_date ?? moment().format("YYYY-MM-DD"),
        tax_date: this.loadedInvoice.tax_date ?? moment().format("YYYY-MM-DD"),
        payment_date:
          this.loadedInvoice.payment_date ??
          moment().add(7, "days").format("YYYY-MM-DD"),
        items: this.loadedInvoice.items ?? [],
      },
    } as Invoice;
  }

  /**
   * Retreive current loaded invoices
   */
  get getInvoices(): Invoice[] {
    return this.Invoices;
  }

  get getVatRates(): VatRate[] {
    return [
      { id: 1, rate: 0 },
      { id: 2, rate: 10 },
      { id: 3, rate: 12 },
      { id: 4, rate: 21 },
    ];
  }

  /**
   * Get last pagination setup
   */
  get getInvoicesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getInvoicesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_INVOICE](Invoice: Invoice) {
    this.loadedInvoice = Invoice;
  }

  @Mutation
  [Mutations.SET_INVOICES](data) {
    this.Invoices = data.data;
  }

  @Mutation
  [Mutations.SET_INVOICES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_INVOICES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_INVOICES](payload) {
    ApiService.setHeader();
    return new Promise<Invoice[]>((resolve, reject) => {
      ApiService.post("invoices", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_INVOICES, data["invoices"]);
            this.context.commit(
              Mutations.SET_INVOICES_PAGINATION,
              data["invoices"].pagination
            );
            resolve(data["invoices"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.cantGetInvoices"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_NEXT_INVOICE_NUMBER](payload) {
    ApiService.setHeader();
    return new Promise<string>((resolve, reject) => {
      ApiService.post("invoice/get-next-invoice-number", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.invoice_number);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.cantGetNextInvoiceNumber"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.ACCOUNTANT_INVOICE_EXPORT](payload) {
    ApiService.setHeader();
    return new Promise<string>((resolve, reject) => {
      ApiService.post("invoices/accountant-invoice-export", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.file);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.cantGetAccountantExport"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.INVOICE_EXPORT](payload) {
    ApiService.setHeader();
    return new Promise<string>((resolve, reject) => {
      ApiService.post("invoices/export", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.file);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.cantGetExport"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ORGANIZATIONS_BY_USER_GROUP_ID](userGroupId: number) {
    ApiService.setHeader();
    return new Promise<Company[]>((resolve, reject) => {
      ApiService.get(
        `company/get-organizations-by-user-group-id/${userGroupId}`
      )
        .then(({ data }) => {
          if (data.success) {
            resolve(data["organizations"]);
          } else {
            const i18string = data.i18;
            const i18params = { userGroupId };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.cantGetOrganizationsByUserGroupId"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_INVOICE_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Invoice>((resolve) => {
        const Invoice = {} as Invoice;
        this.context.commit(Mutations.SET_LOADED_INVOICE, Invoice);
        resolve(Invoice);
      });
    }
    return new Promise<Invoice>((resolve, reject) => {
      ApiService.get(`invoice/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_INVOICE, data["invoice"]);
            resolve(data["invoice"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.commonGET_INVOICE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DOWNLOAD_INVOICE_PDF_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Invoice>((resolve, reject) => {
      ApiService.get(`invoice/${id}/pdf`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["pdf"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.GET_INVOICE_PDF_BY_ID"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Invoice>((resolve, reject) => {
      ApiService.get(`invoice/${id}/payment-instructions`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["payment-instructions"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.GET_INVOICE_PAYMENT_INSTRUCTIONS_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_INVOICE_PDF_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<boolean>((resolve, reject) => {
      ApiService.get(`invoice/${id}/send-email`)
        .then(({ data }) => {
          if (data.success) {
            resolve(true);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.GET_INVOICE_PDF_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CHANGE_PAID_STATUS_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<boolean>((resolve, reject) => {
      ApiService.get(`invoice/${id}/update-payment`)
        .then(({ data }) => {
          if (data.success) {
            resolve(true);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.GHANGE_INVOICE_PAID_STATUS_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_INVOICE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Invoice>((resolve, reject) => {
      ApiService.delete(`invoice/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_INVOICE, data["invoice"]);
            resolve(data["invoice"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.commonDELETE_INVOICE_BY_ID"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.CANCEL_INVOICE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Invoice>((resolve, reject) => {
      ApiService.get(`invoice/cancel/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_INVOICE, data["invoice"]);
            resolve(data["invoice"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.errors.commonCANCEL_INVOICE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_INVOICE](Invoice: Invoice) {
    const payload = JSON.parse(JSON.stringify(Invoice));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`invoice/${Invoice.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_INVOICE, data["invoice"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Invoice.id,
              name: Invoice.number,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.form.invoiceChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_INVOICE_PAYMENTS](Invoice: Invoice) {
    const payload = JSON.parse(JSON.stringify(Invoice));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`invoice/${Invoice.id}/payments`, payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["invoice"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Invoice.id,
              name: Invoice.number,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.form.invoicePaymentsChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_INVOICE](Invoice: Invoice) {
    const payload = JSON.parse(JSON.stringify(Invoice));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`invoice`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_INVOICE, data["invoice"]);
            resolve(data["invoice"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Invoice.id,
              name: Invoice.number,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "invoices.form.invoiceAddFail"
          );
          reject();
        });
    });
  }
}
