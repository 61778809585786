import { Role } from "@/store/interfaces/Role";
import { Country } from "@/store/interfaces/Country";
import i18n from "@/core/plugins/i18n";
import moment from "moment";
import { Contact } from "@/store/interfaces/Contact";
import { UserGroup } from "@/store/interfaces/UserGroup";
import { Login } from "@/store/interfaces/Login";
import { TrainingEvent } from "@/store/interfaces/TrainingEvent";
import { Specialization } from "@/store/interfaces/Specialization";
import { Company } from "@/store/interfaces/Company";
import { DiagnosticRecord } from "@/store/interfaces/DiagnosticRecord";
import { UserProgram } from "@/store/interfaces/Program";
import {
  EyeFlexibilityStatistics,
  EyeStabilityStatistics,
  InOfficeStatistics,
  SensesBoardStatistics,
  SumStatistics,
  VisionEnergyStatistics,
} from "@/store/interfaces/Statistics";
import { Diagnostic } from "@/store/interfaces/Diagnostic";
import { Sport } from "@/store/interfaces/Sport";
import { Language } from "@/store/interfaces/Language";
import { Accessory } from "@/store/interfaces/Accessory";
import { Tool } from "@/store/interfaces/Tool";
import { DashboardModuleItem } from "@/store/interfaces/Dashboard";

export interface CompanyClientGroups {
  company: string;
  company_id: number;
  groups: ClientGroups[];
}
export interface ClientGroups {
  group: string;
  group_id: number;
  groups: ClientGroups[];
  clients: number[];
}

export interface ClientGroup {
  group: UserGroup;
  group_id: number;
  clients: User[];
}

export interface ClientCompanyGroup {
  company: Company;
  company_id: number;
  groups: ClientGroup[];
}

export interface GuestGroup {
  group: UserGroup;
  guests: User[];
}

export interface UserContactEvent {
  id: number;
  user_id: number;
  user: User | null;
  reason: string;
  created_at: string;
  contacted_via:
    | "phone"
    | "email"
    | "in_person"
    | "not_contacted"
    | "sms"
    | "does_not_pick_up"
    | null;
  next_contact_date: string | null;
  next_contact_message: string | null;
  user_notification_id: number | null | undefined;
  result: string | null;
}

export interface UserNotification {
  id: number;
  user_id: number;
  message: string;
  date_start: string;
  created_by_user: User;
  closed: number;
}
export type Notification = UserNotification;

export interface ClientWidgetDataResponse {
  widgets: {
    "after-diagnostic": {
      data: User[];
    };
    "not-in-training": {
      data: User[];
    };
    "ending-program": {
      data: User[];
    };
    "order-diagnostic": {
      data: User[];
    };
    "first-week-of-training": {
      data: User[];
    };
    leads: {
      data: User[];
    };
  };
}

export interface User {
  id: number;
  name: string;
  surname: string;
  full_name: string;
  initials: string;
  email: string;
  phone: string;
  phone_secondary: string;
  password: string;
  avatar: string;
  token: string;
  role_id: number;
  role: Role;
  country_id: number;
  country: Country;
  street: string;
  city: string;
  zip: number;
  birth_date: string;
  email_legal_representative: string | null;
  phone_legal_representative: string | null;
  last_contact_event: UserContactEvent | null;
  other_contacts: Contact[];
  note: string;
  anamnesis: string;
  therapy: number;
  employment: string;
  changed: boolean;
  user_group_id: number;
  user_group: UserGroup;
  logins: Login[];
  logins_count: number;
  training_events: TrainingEvent[];
  specializations: Specialization[];
  overseers: User[];
  overseer_role: string;
  coordinators: User[];
  accessories: Accessory[];
  tools: Tool[];
  specialists: User[];
  assigned_specialists: User[];
  specialist_groups: UserGroup[];
  is_company: number;
  in: string;
  tin: string;
  inv_email: string;
  inv_country_id: number;
  inv_country: Country;
  inv_street: string;
  inv_city: string;
  inv_zip: number;
  invoice_company_id: number;
  invoice_company: Company;
  company_name: string;
  user_company_name: string;
  developer: boolean;
  sports: Sport[];
  diagnostics: Diagnostic[];
  invited: boolean;
  logged_this_week: boolean;
  programs_status: "danger" | "warning" | "success" | null;
  program_paid_status: "danger" | "warning" | "success" | null;
  trainings_status: "danger" | "warning" | "success" | null;
  notDisplayedNotesCount: number;
  last_activity_at: string;
  company_id: number;
  company: Company;
  language_id: number;
  language: Language;
  S2_board_result: SensesBoardStatistics | null;
  vision_energy_result: VisionEnergyStatistics | null;
  in_office_result: InOfficeStatistics | null;
  eye_flexibility_result: EyeFlexibilityStatistics | null;
  eye_stability_result: EyeStabilityStatistics | null;
  diagnostic_records: DiagnosticRecord[];
  assigned_clients: Array<number>;
  user_programs: UserProgram[];
  dash_board_modules: DashboardModuleItem[];
  privacy: PrivacySettings;
  custom_menu: Array<string> | null;
  privacy_change: {
    changed: boolean;
    record: UserPrivacyHistoryRecord;
  };
  statistics: {
    senses_board?: SensesBoardStatistics | null;
    vision_energy?: VisionEnergyStatistics | null;
    in_office?: InOfficeStatistics | null;
    eye_flexibility?: EyeFlexibilityStatistics | null;
    eye_stability?: EyeStabilityStatistics | null;
    sum?: SumStatistics | null;
  };
  notification_to_close: UserNotification | null;
  rank?: number | null;
  filled_invoice_address: boolean;
}
export interface UserPrivacyHistoryRecord {
  id: number;
  privacy: PrivacySettings;
  checked: boolean;
}

export interface PrivacySettings {
  gdpr: boolean | null;
  media: boolean | null;
  research: boolean | null;
}

export const defaultPrivacy = {
  gdpr: null,
  media: null,
  research: null,
};

const _DEFAULT_AVATAR = "/media/avatars/blank.png";
export const showUserAvatar = (user: User) => {
  return user.avatar && user.avatar !== _DEFAULT_AVATAR
    ? user.avatar.startsWith("/media/")
      ? user.avatar
      : process.env.VUE_APP_RESOURCES_ROOT + user.avatar
    : _DEFAULT_AVATAR;
};

export const showUserAvatarByPath = (avatar: string) => {
  return avatar && avatar !== _DEFAULT_AVATAR
    ? avatar.startsWith("/media/")
      ? avatar
      : process.env.VUE_APP_RESOURCES_ROOT + avatar
    : _DEFAULT_AVATAR;
};

export const getUserFullAddress = (user: User): string => {
  if (user.country && user.street && user.zip && user.city) {
    if (i18n.global.te(`countries.nameOverides.${user.country.code}`)) {
      user.country.name = i18n.global.t(
        `countries.nameOverides.${user.country.code}`
      );
    }
    return `${user.street}, ${user.zip} ${user.city}, ${user.country.name}`;
  }
  return "";
};

export const buildShortFullName = (user: User): string => {
  return user.name.charAt(0).toUpperCase() + "." + user.surname;
};

export const getUserFullInvAddress = (user: User): string => {
  if (
    user.company_name &&
    user.inv_country &&
    user.inv_street &&
    user.inv_zip &&
    user.inv_city
  ) {
    if (i18n.global.te(`countries.nameOverides.${user.inv_country.code}`)) {
      user.country.name = i18n.global.t(
        `countries.nameOverides.${user.inv_country.code}`
      );
    }
    return (
      `<div>${user.company_name}</div><div><span class="small">` +
      i18n.global.t("users.account.settings.form.in") +
      `</span>: ${user.in}</div><div><span class="small">` +
      i18n.global.t("users.account.settings.form.tin") +
      `</span>: ${user.tin}</div><div>${user.inv_street}, ${user.inv_zip} ${user.inv_city}, ${user.inv_country.name}</div>`
    );
  }
  return "";
};

export const getUserAge = (user: User): number => {
  if (user.birth_date) {
    return moment().diff(user.birth_date, "years");
  }
  return 0;
};

export const getAge = (date): number => {
  if (date) {
    return moment().diff(date, "years");
  }
  return 0;
};
