export const programCs = {
  programs: {
    toolTips: {
      edit: "Upravit produkt {program}",
      delete: "Odstranit produkt {program}",
      showEshopProduct: "Zobrazit navázaný produkt eshopu",
    },
    noBasicTrainings: "Skupina nema navázané žádné základní produkty",
    editTitle: "Upravit produkt",
    newTitle: "Nový produkt",
    addProgram: "Přidat produkt",
    errors: {
      notFound: "Produkt id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit produkt id {id}",
      nameExists: "Produkt s nazvem `{name}` již existuje",
      commonGET_PROGRAM_BY_ID: "Chyba při získávání dat o produktu",
      commonDELETE_PROGRAM_BY_ID: "Chyba při odstraňování produktu",
      commonDELETE_PROGRAMS: "Nepodařilo se odstranit produkt",
      cantGetPrograms: "Chyba při získávání dat o produktech",
    },
    form: {
      name: "Název",
      type: "Typ produktu",
      name_client: "Název pro klienta",
      nrOfWeeks: "Délka produktu",
      basicPrograms: "Navázané produkty",
      programGroups: "Nadřazený produkt",
      weeks: "týdnů",
      wcProduct: "Eshop produkt",
      company: "Firma",
      specializations: "Specializace",
      invoice_line: "Název pro fakturaci",
      pricelist: {
        title: "Ceník",
        addItem: "Přidat položku",
        changeItem: "Upravit položku",
        period: "Jednotka",
        currency: "Měna",
        price: "Cena",
        unitPriceNotUnique: "Kombinace jednotka - měna musí být unikátní",
      },
      priceCalculator: {
        title: "Délka a cena produktu",
        period: "Jednotka",
        currency: "Měna",
        quantity: "Počet",
        noPricesAvaliable: "Tento produkt nemá zadáné žádné ceny",
        combinationNotFound: "Tato kombinace jednotky a měny není definována",
      },
      trainings: "Tréninky",
      trainingTemplates: "Šablony tréninku",
      programChanged: "Produkt byl upraven",
      programChangedFail: "Nepodařilo se upravit produkt",
      programAdded: "Nový produkt byl vytvořen",
      programAddFail: "Nepodařilo se vytvořit nový produkt",
    },
    types: {
      grouped: "Produktová skupina",
      basic: "Základní",
    },
    table: {
      header: {
        name: "Název",
        type: "Typ produktu",
        basicPrograms: "Navázané produkty",
        programGroups: "Nadřazený produkt",
        name_client: "Název pro klienta",
        nrOfWeeks: "Délka produktu",
        company: "Firma",
        specializations: "Specializace",
        actions: "Akce",
      },

      deleteProgramQuestion:
        "Opravdu chcete odstranit produkt <strong>{program}</strong>?",
      searchPrograms: "Hledat produkty",
      programDeleted: "Produkt {program} byl odstraněn",
      programsDeleted: "Produkty byly odstraněny",
      noRecords: "Nebyly nalezeny žádné produkty",
    },
  },
};
