export const notificationEn = {
  notifications: {
    welcome: "Welcome to iSenses",
    welcomeBack: "Welcome back in iSenses",
    newEshopOrder: "Thank you for your order on senses.zone nr. {orderNr}",
    failedToStartPurchasedProgram:
      "There was an error starting your purchased program on senses.zone order nr. {orderNr}. Please contact iSenses team on {phone} or {email}",
    purchasedProgramStarted:
      "Your purchased program {programName}, order nr. {orderNr} was started. You can start training.",
    clientsMenuUpdated: "Clients menu was updated",
    newSenaptecResultsReceived: "New Senaptec results received",
    companyLogoChanged: "Company logo was updated",
    title: "Notifications",
    toolTips: {
      edit: "Edit notification {notification}",
      delete: "Delete notification {notification}",
    },
    editTitle: "Edit notification",
    newTitle: "New notification",
    addNotification: "Add notification",
    errors: {
      notFound: "Notification {id} not found",
      notYourRole: "Cant access data for notification {id}",
      nameExists: "Notification named `{name}` already exists",
      commonGET_NOTIFICATION_BY_ID: "Error retrieving notification data",
      commonDELETE_NOTIFICATION_BY_ID: "Error deleting notification",
      commonDELETE_NOTIFICATIONS: "Error deleting notifications",
      cantGetNotifications: "Failed to fetch notifications data",
    },
    form: {
      name: "Name",
      company: "Company",
      notificationChanged: "Notification was stored",
      notificationChangedFail: "Failed to store notification",
      notificationAdded: "New notification was created",
      notificationAddFail: "Failed to add new notification",
    },
    table: {
      history: "Notifications",
      addRecord: "Add record",
      header: {
        date_start: "Date start",
        message: "Message",
        closed: "Closed",
      },
      deleteNotificationQuestion:
        "Do you really want to delete  notification <strong>{notification}</strong>?",
      searchNotifications: "Search notifications",
      notificationDeleted: "Notification {notification} was deleted",
      notificationsDeleted: "Notifications were deleted",
      noRecords: "No notifications found",
    },
  },
};
