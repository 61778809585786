export const userContactEventsCs = {
  userContactEvents: {
    description_text:
      "<div>Důvod kontaktování : <strong>{reason}</strong>, kontaktován: <strong>{contacted_via}</strong>.</div><div>Výsledek: <strong>{result}</strong></div>",
    modal: {
      title: "Záznamy o kontaktování uživatele",
      last30: "Posledních 30 záznamů",
    },
    toolTips: {
      contact: "Záznam o kontaktování uživatele {name}",
    },
    errors: {
      cantAddUserContactEvent:
        "Chyba při ukládání záznamu o kontaktování uživatele",
      cantGetUserContactEvents:
        "Chyba při získávání dat o kontaktování uživatele",
    },
    reasons: {
      "not-in-training": "není v tréninku",
      "after-diagnostic": "po diagnostice",
      "ending-program": "končící program",
      "order-diagnostic": "objednání na diagnostiku",
      leads: "potenciální",
      "scheduled-to-contact": "plánované kontaktování",
      "training-renewal": "obnovení tréninku",
      common: "informace",
    },
    via: {
      phone: "telefonem",
      email: "emailem",
      in_person: "osobně",
      not_contacted: "nebyl kontaktován",
      does_not_pick_up: "nezvedá telefon",
      sms: "SMS",
    },
    title: "Záznam o kontaktování uživatele {name}.",
    form: {
      userContactEventAdded: "Záznam o kontaktování uživatele uložen",
      reason: "Důvod",
      contacted_via: "Kontaktován",
      result: "Výsledek",
      nextContactEvent: "Kontaktovat znovu",
      nextDay: "dne",
      note: "poznámka",
    },
  },
};
